<template>
    <v-app id="app_confirmation" class="grey lighten-4">
        <v-container class="px-15 py-9">
            <v-row no-gutters>
                <!-- <v-col cols="4">
                    <h3 class="ml-3">
                        Upcoming appointments 
                        <v-avatar
                            left
                            class="white text-caption font-weight-bold"
                            size="20"
                        >
                            1
                        </v-avatar>
                    </h3>

                    <v-card class="mt-3 pa-4" rounded="4" elevation="1">
                        <p class="text-caption text--disabled mb-1">Tomorrow 10:15am</p>
                        <p class="text-body-2 font-weight-medium mb-1">Men Care Salon | Alkhobar Corniche</p>
                        <p class="text-caption mb-0">Hair Styling, Beard Shaving Steam, Beard Shaving</p>
                    </v-card>

                    <h3 class="mt-5 ml-3">No past appointments</h3>
                    <p class="mt-2 ml-3 text-body-2 text--secondary">You don’t have past appointments. Once you do, they'll show up here. Now get booking!</p>
                </v-col> -->

                <v-col cols="12">
                    <div class="ml-9 mr-5" style="position: relative;height: 525px;background-color: #fff;border-radius: 8px;">
                        <div class="appointment_card app_bottom_card">
                            <v-content>

                                <div class="white--text pt-6 px-10">
                                    <h2>{{ appointment_date }}</h2>

                                    <v-chip
                                        small
                                        color="#037aff"
                                        text-color="white"
                                        class="font-weight-medium"
                                    >
                                        <v-avatar left class="mr-0">
                                            <v-icon class="font-weight-bold" size="15">mdi-checkbox-marked-circle-outline</v-icon>
                                        </v-avatar>
                                        {{appointment_status}}
                                    </v-chip>

                                    <div class="mt-6 d-flex flex-row justify-space-between">
                                        <div>
                                            <p class="text-body-2 font-weight-medium mb-0">{{ appointment_branch }}</p>
                                            <p class="text-caption grey--text mb-0">{{ branch_address }}</p>
                                            <p class="text-caption grey--text">Booking ref: {{ appointment_id }}</p>
                                        </div>
                                        <div @click="rebook_appointment()">
                                            <v-chip color="#404753" text-color="white" label class="py-5">
                                                <v-icon>mdi-calendar-refresh</v-icon>
                                            </v-chip>
                                            <p class="text-caption text-center">Rebook</p>
                                        </div>
                                    </div>
                                </div>

                                <v-container fluid fill-height>
                                    <!-- align-center -->
                                    <v-layout justify-center>
                                        <v-flex xs12 sm8 md4 lg11>
                                            <v-card color="#f2f2f7" class="elevation-1">
                                                <v-card-text>
                                                    <template v-for="(item,i) in services">
                                                        <div class="d-flex flex-row mb-3" :key="i">
                                                        <div class="d-flex flex-column flex-grow-1" style="color: #101928;">
                                                            <div class="text-body-2">{{item.service}}</div>
                                                            <div class="text-caption blue-grey--text text--lighten-1">{{item.duration}}min with {{item.staff_name}}</div>
                                                        </div>
                                                        <div class="d-flex flex-column text-body-1 justify-center" style="color: #101928;">
                                                            <span class="text-body-2">SAR {{item.amount}}</span>
                                                        </div>
                                                    </div>
                                                    </template>
                                                    <!-- <div class="d-flex flex-row mb-3">
                                                        <div class="d-flex flex-column flex-grow-1" style="color: #101928;">
                                                            <div class="text-body-2">Hair Styling</div>
                                                            <div class="text-caption blue-grey--text text--lighten-1">20min with Ahmed</div>
                                                        </div>
                                                        <div class="d-flex flex-column text-body-1 justify-center" style="color: #101928;">
                                                            <span class="text-body-2">SAR 55</span>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex flex-row mb-4">
                                                        <div class="d-flex flex-column flex-grow-1" style="color: #101928;">
                                                            <div class="text-body-2">Beard Shaving</div>
                                                            <div class="text-caption blue-grey--text text--lighten-1">20min with Ahmed</div>
                                                        </div>
                                                        <div class="d-flex flex-column text-body-1 justify-center" style="color: #101928;">
                                                            <span class="text-body-2">SAR 45</span>
                                                        </div>
                                                    </div> -->

                                                    <v-divider></v-divider>

                                                    <div class="d-flex flex-row mt-4 mb-1 text-body-2 blue-grey--text text--lighten-1">
                                                        <div class="d-flex flex-column flex-grow-1">
                                                            <div>Taxes</div>
                                                        </div>
                                                        <div class="d-flex flex-column justify-center">
                                                            SAR {{tax}}
                                                        </div>
                                                    </div>
                                                    <div class="d-flex flex-row mt-4 mb-1 text-body-2 blue-grey--text text--lighten-1">
                                                        <div class="d-flex flex-column flex-grow-1">
                                                            <div>Discount</div>
                                                        </div>
                                                        <div class="d-flex flex-column justify-center">
                                                            SAR {{discount}}
                                                        </div>
                                                    </div>

                                                    <div class="d-flex flex-row text-body-2" style="color: #101928;">
                                                        <div class="d-flex flex-column flex-grow-1">
                                                            <div>Total</div>
                                                        </div>
                                                        <div class="d-flex flex-column justify-center">
                                                            SAR {{total}}
                                                        </div>
                                                    </div>

                                                </v-card-text>
                                            </v-card>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-content>
                        </div>

                        <div style="position: absolute;top: 81%;left: 6%;width: 90%;">
                            <p class="text-body-2 font-weight-medium mb-1">Important info</p>
                            <p class="text-caption" style="line-height: 1rem;">We are proud to serve you, kindly note the following: 1- Please present your Tawakkalna Account. 2- Arrive 10 minutes early to ensure you the best experience. 3- You may bring your shaving stuff with you.</p>
                        </div>
                    </div>

                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import CommonApi from '@/services/CommonApi'
import moment from 'moment'

export default {
    name: "AppointmentConfirmation",

    data: () => ({
        appointment_date : '',
        appointment_id: '',
        appointment_status: 'Confirmed',
        appointment_branch:'',
        services:[],
        tax:0,
        disount:0,
        total:0,
        branch_address:''
    }),

    methods: {
             getAppointmentDetails() {
            this.appointment_id = this.$route.params.appointment_id;
            const post_data = new FormData();
            post_data.append("appointment", this.appointment_id)
            CommonApi.post_data('innosoft_salon.api.checkout', post_data)
            .then(res => {
                if (res.status_code == 200) {
                    console.log(res.data)
                    
                    this.appointment_date = moment(res.data.date).format('dddd, D MMMM Y')
                    this.appointment_branch = res.data.branch
                    this.services = res.data.items
                    this.tax = res.data.tax_total
                    this.total = res.data.grand_total
                    this.discount = res.data.discount_amount
                    this.branch_address = res.data.branch_address
                }
            })
             },
             rebook_appointment(){
                 console.log('asd')
                 this.$router.push('/providers/public-book-appointment')
             }

    },
    mounted() {
        this.getAppointmentDetails();
    },
}

</script>
<style lang="scss">
    .appointment_card {
        height: 50%;
        width: 100%;
        position: absolute;
        background-color: #101928 !important;
        border-color: #101928 !important;

    }
    .app_bottom_card {
        background: #fafafa;
        color: rgba(0,0,0,.87);
    }
</style>